import React, { useState } from "react";
import { createrole } from "../../api/api";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/toast";

const CreateRole = () => {
  const initialstate = {
    role: "",
  };
  const [formsData, setFormData] = useState(initialstate);
  
  const [progress,setProgress] = useState({started:false,pc:0})
    const toast = useToast();
  const navigate = useNavigate()

  const handleChange = (e) => {
    setFormData({ ...formsData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formsData, file: e.target.files });
  };

  const validateForm = () => {
    let errors = {};
    if (formsData.role.length < 4) {
      errors.role = "Role Name Must Be 4 Character.";
       toast({
         title: errors.role,
         status: "warning",
         duration: 2000,
         isClosable: true,
       });
    }
    
    return Object.keys(errors).length === 0;
  };

 

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        let response = await createrole(formsData);
       
        let data = await response.data;
        
        if (data.message) {
           toast({
             title: data.message,
             status: "success",
             duration: 2000,
             isClosable: true,
             position:"top"
           });
          
          let timeout = setTimeout(() => {  
            setFormData(initialstate);
            navigate("/listofroles");
          }, 2000);
          return () => clearTimeout(timeout);
        }
      } catch (error) {
        if (error.response.data) {
          toast({
            title: error.response.data.error,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      }
    }
  };


  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Create a Role</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Role</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Create a Role
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-10">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={formSubmitHandler}
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label htmlFor="role" className="col-form-label">
                                Role :
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="role"
                                id="role"
                                onChange={handleChange}
                                value={formsData.role}
                              ></input>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button className="btn btn-primary" type="submit">
                          Create Role
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRole;
