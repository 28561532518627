import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Header from '../pages/Header/Header'
import Footer from '../pages/Footer/Footer'

const PrivateRoute = () => {

    const authorization = localStorage.getItem("rove-token")


  return (<>
    <Header/>
      <main>
        {authorization?<Outlet/> :<Navigate to="/"/>}</main>
        <Footer></Footer>
        </>
  )
}

export default PrivateRoute