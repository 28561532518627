import React from 'react'

const NotFound = () => {
  return (
    <div
      className="text-center d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="scrolling-text-container">
        <h3 className="text-danger">404 Page Not Found!</h3>
      </div>
    </div>

    // <div className='text-center d-flex justify-content-center align-items-center  ' style={{height:"100vh"}}>
    //     <marquee direction="down" behavior="alternate" width="400" height="400">
    //        <marquee behavior="alternate" ><h3 className='text-danger'>404 Page Not Found!</h3></marquee> </marquee>

    // </div>
  );
}

export default NotFound
