import React, {  useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {useToast} from "@chakra-ui/toast"

import {
  readmessage,
  sendmessage,
  updateticket,
  deleteMessage,
  fetchallusers,
  updateupload,
} from "../api/api";
import { viewticket } from "../api/api";

const ListOfStudy = () => {
  const location = useLocation();
  const [loginUser, setLoginUser] = useState(null);
  const [ticket, setTicket] = useState({});
  const [user, setUsers] = useState([]);
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [assign_id, setAssign] = useState({ _id: "", username: "" });
  const [message, setMessage] = useState({ ticketId: "", message: "" });
  const [error, setError] = useState({});
  const [ids, setId] = useState("");
  const didMountRef = useRef(false);
  const didMount = useRef(false);
  const didM = useRef(false);
  const [allMessage, setAllMessage] = useState([]);
  const [file, setFile] = useState([])
 const toast = useToast();
  let parsedData;
  useEffect(() => {
    if (location && location.search) {
      // Parse the query string from the URL
      parsedData = queryString.parse(location.search);

      setId(parsedData._id);
      showticket(ids);
      setStatus(ticket.status);
      setPriority(ticket.priority);
    }
  }, [location]);

  useEffect(() => {
    let data = localStorage.getItem("rove-user");
    if (data !== null) {
      data = JSON.parse(data);
      setLoginUser(data);
    } else {
      console.log("No Data..");
    }
  }, []);

  useEffect(() => {
    console.log(loginUser);
  }, [loginUser]);


  const handleFileChange = ( e,id) => {
    
    setFile(e.target.files );
    
    setId(id)
  };

  const handleFileUpload = async(e,id,ticket) => {
    e.preventDefault()
   
    try {
      if (file.length!== 0) {
        let form = new FormData();
        form.append("ticket_id",ticket)
        for (let i = 0; i < file.length; i++) {
         
          form.append("files", file[i]);
        }
        let response = await updateupload(id, form);
        let data = response.data
        if (data.message) {
          toast({
            title: data.message,
            description: "We've added this file in this ticket.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Please add a File.",
          status: "warning",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      if (error.response.data.error) {
        toast({
          title: error.response.data.error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    
    
  }

  const updateStatus = async (id, status) => {
    try {
      const response =await updateticket(id, { status: status });      
    } catch (error) {
      console.log(error);
    }
  };
  const updatePriority = async (id, priority) => {
    try {
      const response =await updateticket(id, { priority: priority });
    } catch (error) {
      console.log(error);
    }
  };
  const updateAssign = async (id, assign_id) => {
    try {
      const response =await updateticket(id, { assign_id: assign_id });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAssign = async () => {
    try {
      let response = await fetchallusers();
      let data = response.data;
      if (data.users) {
        let users = data.users;
        users = users.filter((user) => {
          return user.isAdmin !== true;
        });
        setUsers(users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssign();
  }, []);

  const showticket = async (id) => {
    try {
      let response = await viewticket(id);
      let data = response.data;
      setTicket(data.ticket);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      updateStatus(ids, status);
      showticket(ids);
    } else {
      didMountRef.current = true; // Mark the component as mounted after the first render
    }
  }, [status, didMountRef]);

  useEffect(() => {
    if (didMount.current) {
      updatePriority(ids, priority);
      showticket(ids);
    } else {
      didMount.current = true; // Mark the component as mounted after the first render
    }
  }, [priority, didMount]);

  useEffect(() => {
    if (didM.current) {
      updateAssign(ids, assign_id);
      showticket(ids);
    } else {
      didM.current = true; // Mark the component as mounted after the first render
    }
  }, [assign_id, didM]);

  const handleChange = (id, e) => {
    setStatus(e.target.value);
    setId(id);
  };

  const priorityHandleChange = (id, e) => {
    setPriority(e.target.value);
    setId(id);
  };
  const assignHandleChange = (id, e) => {
    setAssign(e.target.value);

    setId(id);
  };

  const messageHandler = (id, e) => {
    setMessage({ ticketId: id, message: e.target.value });
  };


  const messageSubmit = async (e) => {
    e.preventDefault();
    if (message.message.length > 3) {
      try {
        let response = await sendmessage(message);
        let data = response.data;
        console.log(data);
        if (data.ticket_message) {
          fetchMessage(ticket._id);
          toast({
            title: data.message,
            status: "success",
            duration: 3000,
            isClosable: true,
            position:"top"
          });

          setMessage({ ticketId: "", message: "" });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast({
        title: "Please, Write some Message.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const fetchMessage = async (id) => {
    try {
      let response = await readmessage(id);
      let data = response.data;
      if (data.message) {
        setAllMessage(data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMessage(parsedData._id);
  }, [location]);

  const deletemessage = async (id) => {
    try {
      if (!id) {
        setError({ dele: "Somthing Has Been Wrong !" });
        return false;
      }
      const response = await deleteMessage(id);
      let data = response.data;
      console.log(data)
      if (data.messages) {
        toast({
          title: data.messages,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      

      fetchMessage(ticket._id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">View Ticket</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">
                          {loginUser && loginUser.isAdmin === true
                            ? "Admin"
                            : "User"}
                        </a>
                      </li>
                      <li className="breadcrumb-item active">View Ticket</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div
                      className={
                        (loginUser !== null &&
                        loginUser.isAdmin === false )?
                        `d-flex justify-content-center`:""
                      }
                    >
                      <div className="row">
                        <div className=" col-lg-4 col-md-6">
                          <table cellPadding="5">
                            <tbody>
                              <tr>
                                <td className="tktDspLabel">
                                  Ticket&nbsp;ID :
                                </td>

                                <td className="tktDspValue" colSpan="3">
                                  {ticket.ticket_id}
                                </td>
                              </tr>

                              <tr>
                                <td className="tktDspLabel">Title :</td>
                                <td
                                  className="tktDspValue"
                                  style={{ fontWeight: "bolder" }}
                                >
                                  {ticket.title}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Summary:</td>
                                <td
                                  className="tktDspValue multiline-cell"
                                  colSpan="3"
                                  style={{
                                    maxWidth: "1000px",
                                    overflowX: "auto",
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {ticket.summary}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Status :</td>
                                <td className="tktDspValue">{ticket.status}</td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Priority :</td>
                                <td className="tktDspValue">
                                  {ticket.priority}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Assign To:</td>
                                <td className="tktDspValue">
                                  {ticket.assign_id !== undefined
                                    ? ticket.assign_id.username
                                    : "Please,Assign To Someone."}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Created Date :</td>
                                <td className="tktDspValue">
                                  {ticket.createdAt}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">
                                  Expected Solving:
                                </td>
                                <td className="tktDspValue">
                                  {ticket.deadline}
                                </td>
                              </tr>
                              <tr>
                                <td className="tktDspLabel">Updated Date :</td>
                                <td className="tktDspValue">
                                  {ticket.updatedAt}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        {loginUser !== null && loginUser.isAdmin === true && (
                          <div className=" col-lg-4 col-md-4">
                            <form className="adminupdate">
                              <div className="mb-3 d-flex">
                                <label
                                  htmlFor="role-Responsibility"
                                  className="col-form-label"
                                >
                                  Status :
                                </label>

                                <select
                                  id="role-Responsibility"
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="status"
                                  onChange={(e) => handleChange(ticket._id, e)}
                                  value={ticket.status}
                                  placeholder="Change Status"
                                >
                                  {/* <option disabled>Change Status</option> */}
                                  <option value="Open">Open</option>
                                  <option value="In Progress">
                                    In Progress
                                  </option>
                                  <option value="Resolved">Resolved</option>
                                  <option value="Closed">Closed</option>
                                </select>
                              </div>
                              <div className="mb-3 d-flex">
                                <label
                                  htmlFor="role-priority"
                                  className="col-form-label"
                                >
                                  Priority :
                                </label>

                                <select
                                  id="role-priority"
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="priority"
                                  onChange={(e) =>
                                    priorityHandleChange(ticket._id, e)
                                  }
                                  value={ticket.priority}
                                >
                                  <option value="Low">Low</option>
                                  <option value="Medium">Medium</option>
                                  <option value="High">High</option>
                                </select>
                              </div>
                              <div className="mb-3 d-flex">
                                <label
                                  htmlFor="role-Assign"
                                  className="col-form-label"
                                >
                                  Assign User :
                                </label>

                                <select
                                  id="role-Assign"
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="assign_id"
                                  onChange={(e) =>
                                    assignHandleChange(ticket._id, e)
                                  }
                                  placeholder="Select User"
                                  // value={ticket.assign_id}
                                >
                                  <option defaultValue value="">
                                    Select User
                                  </option>
                                  {user.length > 0 &&
                                    user.map((u) => (
                                      <option
                                        key={u._id}
                                        value={`${u._id} ${u.username}`}
                                      >
                                        {/* value={`${u._id} ${u.username}`} */}
                                        {u.username}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </form>
                            <div className="mb-3 d-flex">
                              <form
                                encType="multipart/form-data"
                                onSubmit={(e) =>
                                  handleFileUpload(
                                    e,
                                    ticket._id,
                                    ticket.ticket_id
                                  )
                                }
                              >
                                <label
                                  htmlFor="file"
                                  className="col-form-label"
                                >
                                  Attachments:
                                </label>
                                <input
                                  multiple
                                  type="file"
                                  name="file"
                                  id="file"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleFileChange(e, ticket._id)
                                  }
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-sm my-3"
                                >
                                  Upload
                                </button>
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row my-4">
                      <div
                        className={
                          loginUser !== null && loginUser.isAdmin === false
                            ? ` col-lg-12 col-md-12 text-center`
                            : `col-lg-4 col-md-4`
                        }
                      >
                        <form onSubmit={messageSubmit}>
                          <div className="mb-3">
                            {error.message && (
                              <h6 style={{ color: "green", margin: "5px 0" }}>
                                {error.message}
                              </h6>
                            )}
                            <textarea
                              // className="w-40"
                              name="message"
                              onChange={(e) => messageHandler(ticket._id, e)}
                              id="message"
                              cols="30"
                              rows="3"
                              placeholder="  Send Messages"
                              value={message.message}
                              style={{
                                width: "320px",
                                background: "transparent",
                                border:"1px solid black"
                              }}
                            ></textarea>
                            
                          </div>

                          <button
                            type="submit"
                            className="btn btn-primary ms-1 "
                          >
                            Sent
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="text-center">Messages</h4>
                {allMessage.length === 0 && (
                  <h5 className="m-4 text-center">No Previeous Messages.</h5>
                )}
                {allMessage.map((message) => (
                  <>
                    <div className="card my-4" key={message._id}>
                      <div className="card-body">
                        <div className="row my-2">
                          <div className="d-flex justify-content-evenly">
                            <div className="w-50">
                              <p>Message: {message.message}</p>
                              <p>To: {message.to}</p>
                            </div>
                            <div>
                              <button
                                type="button"
                                onClick={() => deletemessage(message._id)}
                                className="btn btn-sm btn-info"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListOfStudy;
