import React, { useState, useEffect, useContext } from "react";
import ReportTemplate from "../component/ReportTemplate";
import jsPDF from "jspdf";
import { TicketContext } from "../utils/context";
import { useToast } from "@chakra-ui/toast";
import FileSaver, * as FILESAVER from "file-saver"
import XLSX from "sheetjs-style"

const GeneratePdf = () => {
  const [tickets, setTickets] = useState([]);
  const toast = useToast();

  const { filterTicket } = useContext(TicketContext);


  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"

  const fileEXT = ".xlsx"



  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(filterTicket)
    const wb = {Sheets:{"data":ws},SheetNames:["data"]}
    const excelBuffer = XLSX.write(wb,{bookType:"xlsx",type:"array"})
    const data = new Blob([excelBuffer],{type:fileType})
    FileSaver.saveAs(data,`document`+fileEXT)
  }


  const handleGeneratePdf = () => {
    const doc = new jsPDF("p", "pt", "a4");

    // Adding the fonts.
    doc.setFont("Lato-Regular");
    doc.html(document.querySelector("#content"), {
      callback: function (pdf) {
        pdf.setFontSize(4);
        pdf.save("dcoument.pdf");
      },
      html2canvas: { scale: 0.7 },
    });
  };

  const fetchTickets = () => {
    setTickets(filterTicket);
    if (filterTicket === null) {
      toast({
        title: "Please Select Specific Filter.",
        description: "You Need To Select Specific Filtered Data For Generate Sheet.",
        status: "warning",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };
  useEffect(() => {
    fetchTickets();
  }, []);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Generate Ticket PDF</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    {/* <li className="breadcrumb-item">
                      <a href="#">Super Admin</a>
                    </li> */}
                    <li className="breadcrumb-item active">
                      Generate Ticket PDF
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <div className="card-body">
                <div className="row d-flex justify-content-end">
                  {/* <div className="col-md-3 mb-3">
                    <h5
                      className="col-form-label"
                      style={{ fontWeight: "bold" }}
                    >
                      Filter
                    </h5>

                    <select
                      id="role-select"
                      className="form-select"
                      aria-label="Default select example"
                      name="status"
                      onChange={(e) => setFilter(e.target.value)}
                      value={filter}
                    >
                      <option defaultValue value="">
                        Select Status
                      </option>
                      <option value="Open">Open</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Resolved">Resolved</option>
                      <option value="Closed">Closed</option>
                    </select>
                  </div> */}
                  <div className="col-md-4 d-flex align-items-center justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={exportToExcel}
                    >
                      Generate Sheet
                    </button>
                  </div>
                </div>
                <div id="content">
                  <ReportTemplate data={tickets} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratePdf;
