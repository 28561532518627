import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { changepassword } from "../../api/api";

const Header = () => {
  const Navigate = useNavigate();
  const [user, setUser] = useState(null);
  let initialpass = {
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
  };
  const [passwordData, setPasswordData] = useState(initialpass);
  const [error, setError] = useState({});
const [showCurrentPassword, setShowCurrentPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


  const logoutHandler = () => {
    localStorage.removeItem("rove-token");
    localStorage.removeItem("rove-user");

    Navigate("/");
  };
  
  const togglePasswordVisibility = (passwordType) => {
    switch (passwordType) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  let timeout = () => {
    let Timeout = setTimeout(() => {
      setError({})
    }, 2000)
    return () => clearTimeout(Timeout)
  }

  const handleChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    const errors = {};
    if (passwordData.newpassword.length < 6) {
      errors.newpassword = "New-Password Length Must Be 6.";
    }
    if (passwordData.currentpassword.length === 0) {
      errors.currentpassword = "Please, Enter Current-Password.";
    }
    if (passwordData.currentpassword === passwordData.newpassword) {
      errors.newpassword = "New-Password Does Not Same To Current Password.";
    }
    if (passwordData.newpassword !== passwordData.confirmpassword) {
      errors.confirmpassword =
        "Confirm-Password Does Not Match To New-Password.";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        let response = await changepassword(passwordData)
        let data = response.data
        if (data.message) {
          setError({ message: data.message })
          let log_out = setTimeout(() => {
            Navigate("/")
            localStorage.removeItem("rove-token")
            localStorage.removeItem("rove-user")
          }, 2000)
          return()=>clearTimeout(log_out)
         
        }
      } catch (error) {
        if (error.response.data.error) {
          setError({ error: error.response.data.error });
          timeout()
        }
      }
    }
  };

  useEffect(() => {
    let userDetail = localStorage.getItem("rove-user");
    if (userDetail) {
      userDetail = JSON.parse(userDetail);
      setUser(userDetail);
      console.log(user);
    } else {
      console.error("No user details found in localStorage");
    }
  }, []);

  useEffect(() => {
    console.log(user);
  }, [user]);

  const close = () => {
    timeout()
    setPasswordData(initialpass)
  }

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}{" "}
      <div
        className="modal fade"
        id="changePassordModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Change Password
              </h5>
              <button
                type="button"
                onClick={() => close()}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={submitHandler}>
                <div className="my-3">
                  {error.error && (
                    <h6 style={{ color: "red" }}>{error.error}</h6>
                  )}
                  {error.message && (
                    <h6
                      style={{ color: "green" }}
                    >{`${error.message}.. Please Re-Login.`}</h6>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="Current">
                    Current password{" "}
                  </label>
                  <div className="input-group auth-pass-inputgroup">
                    <input
                      name="currentpassword"
                      id="Current"
                      type={showCurrentPassword ? "text" : "password"}
                      onChange={handleChange}
                      value={passwordData.currentpassword}
                      className="form-control"
                      placeholder="Enter Current password"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />
                    <button
                      className="btn btn-light password-addon"
                      type="button"
                      id="password-addon"
                      onClick={() => togglePasswordVisibility("current")}
                    >
                      <i
                        className={`mdi mdi-eye${
                          showCurrentPassword ? "" : "-off"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {error.currentpassword && (
                    <h6 style={{ color: "red", margin: "5px 0" }}>
                      {error.currentpassword}
                    </h6>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="New">
                    New password
                  </label>
                  <div className="input-group auth-pass-inputgroup">
                    <input
                      name="newpassword"
                      id="New"
                      onChange={handleChange}
                      value={passwordData.newpassword}
                      type={showNewPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="New password"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />
                    <button
                      className="btn btn-light password-addon"
                      type="button"
                      id="password-addon1"
                      onClick={() => togglePasswordVisibility("new")}
                    >
                      <i
                        className={`mdi mdi-eye${
                          showNewPassword ? "" : "-off"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {error.newpassword && (
                    <h6 style={{ color: "red", margin: "5px 0" }}>
                      {error.newpassword}
                    </h6>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="Confirm">
                    Confirm password
                  </label>
                  <div className="input-group auth-pass-inputgroup">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmpassword"
                      id="Confirm"
                      onChange={handleChange}
                      value={passwordData.confirmpassword}
                      className="form-control"
                      placeholder="Confirm password"
                      aria-label="Password"
                      aria-describedby="password-addon"
                    />
                    <button
                      className="btn btn-light password-addon"
                      type="button"
                      id="password-addon2"
                      onClick={() => togglePasswordVisibility("confirm")}
                    >
                      <i
                        className={`mdi mdi-eye${
                          showConfirmPassword ? "" : "-off"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {error.confirmpassword && (
                    <h6 style={{ color: "red", margin: "5px 0" }}>
                      {error.confirmpassword}
                    </h6>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => close()}
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/superadmindashbord" className="logo logo-light">
                <span className="logo-sm">
                  <img
                    style={{ maxWidth: "100px", width: "auto" }}
                    src="assets/images/logo-rove.png"
                    alt="Header Avatar"
                  />
                </span>
                <span className="logo-lg">
                  <img
                    style={{ maxWidth: "100px", width: "auto" }}
                    src="assets/images/logo-rove.png"
                    alt="Header Avatar"
                  />
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm px-3 d-lg-none font-size-16  header-item waves-effect waves-light"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <span
                    className="mdi mdi-account-outline"
                    style={{ fontSize: "25px" }}
                  ></span>
                  <span className="d-none d-xl-inline-block ms-1">
                    {user !== null ? user.name : "Loading..."}
                  </span>
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </div>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <!-- item--> */}
                <Link className="dropdown-item" to="/edit-profile">
                  <i className="bx bx-user font-size-16 align-middle me-1"></i>{" "}
                  <span key="t-profile">Profile</span>
                </Link>
                <a
                  className="dropdown-item"
                  href="/"
                  data-bs-toggle="modal"
                  data-bs-target="#changePassordModal"
                >
                  <i className="mdi mdi-lock font-size-16 align-middle me-1"></i>{" "}
                  <span key="t-profile">Change Password</span>
                </a>

                <div className="dropdown-divider"></div>

                <a
                  href="#"
                  role="button"
                  className="dropdown-item text-danger"
                  onClick={logoutHandler}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
                  <span key="t-logout">Logout</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="topnav">
        <div className="container-fluid">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
            <div className="collapse navbar-collapse" id="topnav-menu-content">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/superadmindashbord"
                    // id="topnav-dashboard"
                    role="button"
                  >
                    <div className="d-flex align-items-center">
                      <i className="bx bx-home-circle me-2"></i>
                      <span key="t-dashboards">Dashboards</span>
                    </div>
                  </Link>
                </li>
                {user !== null && user.isAdmin === true && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="/listof-users"
                      // id="topnav-ecommerce"
                      role="button"
                    >
                      <div className="d-flex align-items-center">
                        <i className="bx bx-user-circle me-2"></i>
                        <span key="t-ecommerce">Users</span>
                        {/* <div className="arrow-down"></div> */}
                      </div>
                    </Link>
                    {/* <div
                      className="dropdown-menu"
                      aria-labelledby="topnav-ecommerce"
                    >
                      <Link
                        to="/create-user"
                        className="dropdown-item"
                        // key="t-read-email"
                      >
                        Create User
                      </Link>
                      <Link
                        to="/listof-users"
                        className="dropdown-item"
                        // key="t-read-email"
                      >
                        List of Users
                      </Link>
                    </div> */}
                  </li>
                )}

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    to="/listoftickets"
                    id="topnav-ecommerce"
                    role="button"
                  >
                    <div className="d-flex align-items-center">
                      <i className="fas fa-laptop-house me-2"></i>
                      <span key="t-ecommerce">Ticket</span>
                      {/* <div className="arrow-down"></div> */}
                    </div>
                  </Link>
                  {/* <div
                    className="dropdown-menu"
                    aria-labelledby="topnav-ecommerce"
                  >
                    <Link
                      to="/createticket"
                      className="dropdown-item"
                      // key="t-read-email"
                    >
                      Create Ticket
                    </Link>
                    <Link
                      to="/listoftickets"
                      className="dropdown-item"
                      key="t-inbox"
                    >
                      List Of Tickets
                    </Link>
                  </div> */}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
