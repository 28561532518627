import React, { useState, useContext } from "react";
import { createuser } from "../api/api";
import { useNavigate } from "react-router";
import { TicketContext } from "../utils/context";
import { useToast } from "@chakra-ui/toast";

const CreateUser = () => {
  let initialstate = {
    name: "",
    username: "",
    email: "",
    password: "",
    confirmpassword: "",
    isAdmin: false,
  };
  const [formData, setFormData] = useState(initialstate);
  const toast = useToast();

  const navigate = useNavigate();
  const { loginUser } = useContext(TicketContext);

  if (loginUser !== null && loginUser.isAdmin === false) {
    navigate("/createticket");
  }

  const inputChangeHandler = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateForm = () => {
    let errors = {};
    if (formData.name.length === 0 || formData.name.length < 3) {
      errors.name = "Enter Name And Name Must be 3 Characters.";
      toast({
        title: errors.name,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formData.email)) {
      if (formData.email.length === 0) {
        errors.email = "Enter Email Address.";
      } else {
        errors.email = "Enter Valid Email Address.";
      }
      toast({
        title: errors.email,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    if (formData.username.length === 0 || formData.username.length < 3) {
      errors.username = "Username Must be 3 Characters.";
      toast({
        title: errors.username,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    if (formData.password.length < 6) {
      errors.password = "Password Must be At Least 6 Characters.";
      toast({
        title: errors.password,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    if (formData.password !== formData.confirmpassword) {
      errors.confirmpassword = "Password Not Match To Confirm Password.";
      toast({
        title: errors.confirmpassword,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }

    return Object.keys(errors).length === 0;
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        let response = await createuser(formData);
        let data = response.data;
        if (data.error) {
          toast({
            title: data.error,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });

          setFormData(initialstate);
        }
      } catch (error) {
        if (error.response) {
          toast({
            title: error.response.data.error,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      }
    }
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Create User</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Company Admin</a>
                      </li>
                      <li className="breadcrumb-item active">Create User</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                   
                    <form className="outer-repeater" onSubmit={submitHandler}>
                      <div className="row ">
                        <div className="d-flex flex-column align-items-center">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="role-name"
                                className="col-form-label"
                              >
                                Name:
                              </label>
                              <input
                                onChange={inputChangeHandler}
                                name="name"
                                type="text"
                                className="form-control"
                                id="role-name"
                                value={formData.name}
                              />
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="role-email"
                                className="col-form-label"
                              >
                                Email:
                              </label>
                              <input
                                onChange={inputChangeHandler}
                                name="email"
                                type="text"
                                className="form-control"
                                id="role-email"
                                value={formData.email}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="role-uname"
                                className="col-form-label"
                              >
                                Username:
                              </label>
                              <input
                                onChange={inputChangeHandler}
                                name="username"
                                type="text"
                                className="form-control"
                                id="role-uname"
                                value={formData.username}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="role-pass"
                                className="col-form-label"
                              >
                                Password:
                              </label>
                              <input
                                onChange={inputChangeHandler}
                                name="password"
                                type="text"
                                className="form-control"
                                id="role-pass"
                                value={formData.password}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label
                                htmlFor="role-CP"
                                className="col-form-label"
                              >
                                Confirm Password:
                              </label>
                              <input
                                name="confirmpassword"
                                onChange={inputChangeHandler}
                                type="password"
                                className="form-control"
                                id="role-CP"
                                value={formData.confirmpassword}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <div className=" form-check">
                                <input
                                  name="isAdmin"
                                  onChange={inputChangeHandler}
                                  type="checkbox"
                                  className="form-check-input"
                                  id="exampleCheck1"
                                  checked={formData.isAdmin}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="exampleCheck1"
                                >
                                  User Is Admin
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-center mb-4">
                        <button
                          type="submit"
                          className="btn btn-primary mt-3 mt-lg-0 me-3"
                        >
                          Create User
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary mt-3 mt-lg-0 me-3"
                          onClick={() => setFormData(initialstate)}
                        >
                          Clear
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
