import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getalltickets } from "../../api/api";
import { TicketContext } from "../../utils/context";

const SuperAdminDashbord = () => {
  const [admin, setAdmin] = useState(false);
  const [counter, setCounter] = useState({
    open: 0,
    running: 0,
    resolved: 0,
    closed: 0,
    total: 0,
  });
  const { loginUser } = useContext(TicketContext);

  const navigate = useNavigate()

    useEffect(() => {
      let user = localStorage.getItem("rove-user");
      if (user) {
        user = JSON.parse(user);
        
      } else {
        console.log("No User Found");
      }
    }, []);

   useEffect(() => {
     fetchTickets();
   }, [admin]);

//  if (loginUser !== null && loginUser.isAdmin === false) {
//    navigate("/createticket");
//  }
  
  const fetchTickets = async () => {
    try {
      let response = await getalltickets();
      let data = response.data;
      if (data.counter) {
        let x = data.counter;
        let tR = data.totalresult;
        setCounter({ ...counter, ...x, total: tR });
      }
    } catch (error) {
      console.log(error);
    }
  };



 


  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">
                   Super Admin
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item active">
                        <a href="#">Super Admin</a>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-3">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <Link to="/user-menu-deshbord">
                          <div className="text-center">
                            <h2 className="font-size-24 text-primary">
                              Total Tickets <br /> {counter.total}
                            </h2>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <Link to="/user-menu-deshbord">
                          <div className="text-center">
                            <h2 className=" font-size-24 text-primary">
                              Open Tickets <br /> {counter.open}
                            </h2>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <Link to="/user-menu-deshbord">
                          <div className="text-center">
                            <h2 className=" font-size-24 text-primary">
                              Running Tickets <br /> {counter.running}
                            </h2>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <Link to="/user-menu-deshbord">
                          <div className="text-center">
                            <h2 className=" font-size-24 text-primary">
                              Resolved Tickets <br />{counter.resolved}
                            </h2>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <Link to="/user-menu-deshbord">
                          <div className="text-center">
                            <h2 className=" font-size-24 text-primary">
                              Closed Tickets <br /> {counter.closed}
                            </h2>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="card mini-stats-wid">
                      <div className="card-body">
                        <Link to="/role-menu-dashbord">
                          <div className="text-center">
                            <div className="d-inline-block mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className="mdi mdi-tools font-size-24"></i>
                              </span>
                            </div>
                            <p className="text-muted fw-medium"></p>
                            <h2 className="mb-4 font-size-24 text-primary">
                              Role
                            </h2>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminDashbord;
