import Home from "./pages/Home/Home";
import SuperAdminDashbord from "./pages/Super-Admin-Dashbord/SuperAdminDashbord";
import {  Route, Routes} from "react-router-dom";
import UserMenuDashbord from "./pages/Admin-Config-Pages/User-Menu-Dashbord/UserMenuDashbord";

import ListofUsers from "./pages/Admin-Config-Pages/ListofUsers/ListofUsers";
import AdminMenuDashbord from "./pages/User-Pages/Admin-Menu-Dashbord/AdminMenuDashbord";
import TicketMenuDashbord from "./pages/TicketMenuDashbord";
import RoleMenuDashbord from "./pages/RoleMenuDashbord";
import CreateTicket from "./pages/Ticket/CreateTicket";
import CreateRole from "./pages/Role/CreateRole";
import ListofTickets from "./pages/ListofTickets";
import ListofRoles from "./pages/ListofRoles";
import ListOfStudy from "./pages/ViewTicket";
import CreateUser from "./pages/CreateUser";
import EditProfile from "./pages/Update-User-Profile/EditProfile";
import PrivateRoute from "./component/PrivateRoute";
import NotFound from "./pages/404/NotFound";
import GeneratePdf from "./pages/GeneratePdf";
import "./app.css"

function App() {
  return (
    <>
      <div id="layout-wrapper" className="app">
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route element={<PrivateRoute />}>
            <Route exact path="/edit-profile" element={<EditProfile />}></Route>
            <Route
              exact
              path="/superadmindashbord"
              element={<SuperAdminDashbord />}
            ></Route>
            {/* <Route
              exact
              path="/user-menu-deshbord"
              element={<UserMenuDashbord />}
            ></Route> */}

            <Route exact path="/listof-users" element={<ListofUsers />}></Route>
            <Route
              exact
              path="/admin-menudashbord"
              element={<AdminMenuDashbord />}
            ></Route>

            {/* <Route
              exact
              path="/role-menu-dashbord"
              element={<RoleMenuDashbord />}
            ></Route>
            <Route
              exact
              path="/ticketmenudashbord"
              element={<TicketMenuDashbord />}
            ></Route> */}
            <Route
              exact
              path="/createticket"
              element={<CreateTicket />}
            ></Route>
            <Route
              exact
              path="/listoftickets"
              element={<ListofTickets />}
            ></Route>
            <Route
              exact
              path="/createrole"
              element={<CreateRole />}
            ></Route>
            <Route
              exact
              path="/listofroles"
              element={<ListofRoles />}
            ></Route>
            <Route exact path="/listof-study" element={<ListOfStudy />}></Route>
            <Route exact path="/create-user" element={<CreateUser />}></Route>
            <Route exact path="/genpdf" element={<GeneratePdf />}></Route>
          </Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
