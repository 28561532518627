import React, { useEffect, useState } from "react";
import { updateuser } from "../../api/api";

const EditProfile = () => {
  const [formData, setFormData] = useState({ name: "", email: "" });
const [error,setError] = useState({})
  const [user, setUser] = useState(null);
  useEffect(() => {
    let data = localStorage.getItem("rove-user");
    if (data) {
      data = JSON.parse(data);
      setUser(data);
      setFormData({ name: data.name, email: data.email })
      setError({ error: "You Can Only Change Your Name & Email." })
      timeout()
    } else {
      console.log("No USER FOUND.");
    }
  }, []);

  const timeout = () => {
    let Timeout = setTimeout(() => {
      setError({})
    },2000)
    return ()=>clearTimeout(Timeout)
  }
  useEffect(() => {
    console.log(user, "USER");
  }, [user]);

  const validate = () => {
    let errors = {}
    if (formData.name.length < 3) {
      errors.name = "Please,Enter Valid Name."
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(formData.email)) {
      if (formData.email.length === 0) {
        errors.email = "Enter Email Address.";
      } else {
        errors.email = "Enter Valid Email Address.";
      }
    }
    setError(errors)
    return Object.keys(errors).length ===0
  }

  const handelChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    
  }

  const submitForm = async (id, e) => {
    e.preventDefault()
   
    if (validate()) {
      try {
         let response = await updateuser(id, formData);
         let data = await response.data;
        console.log(data);
        if (data.message) {
          setError({ message: data.message })
          // setFormData({ name: data.response.name, email: data.response.email })
          localStorage.setItem("rove-user",JSON.stringify(data.response))
          timeout()
        }
      } catch (error) {
        if (error.response.data.error) {
          setError({ error: (error.response.data.error ||"Some Internal Server Error." )})
          timeout()
        }
      }
     
    }
  }

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <div className="row">
                      <div className="col-8">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Update Your info.</p>
                        </div>
                      </div>
                      <div className="col-4 align-self-end">
                        <img
                          src="assets/images/profile-img.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <div className="auth-logo">
                      <a href="index.html" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src="assets/images/logo-light.svg"
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </a>

                      <a href="#" role="button" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <img
                            src="assets/images/users/avatar-1.jpg"
                            alt=""
                            className="avatar-title rounded-circle bg-light"
                            height="34"
                          />
                        </div>
                      </a>
                    </div>
                    <div className="p-2">
                      <form className="form-horizontal">
                        <div className="row">
                            <div className="my-3">
                              {error.error && (
                                <h6 style={{ color: "red" }}>{error.error} </h6>
                              )}
                              {error.message && (
                                <h6 style={{ color: "green" }}>{error.message} </h6>
                              )}
                            </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="username" className="form-label">
                                Name
                              </label>
                              <input
                                type="text"
                                name="name"
                                onChange={handelChange}
                                className="form-control"
                                id="username"
                                placeholder="Enter First name"
                                value={
                                  user !== null ? formData.name : "Loading.."
                                }
                              />
                              {error.name && (
                                <h6 style={{ color: "red" }}>{error.name} </h6>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="mb-3">
                              <label htmlFor="email" className="form-label">
                                Email
                              </label>
                              <input
                                name="email"
                                onChange={handelChange}
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter Email"
                                value={
                                  user !== null ? formData.email : "Loading.."
                                }
                              />
                              {error.email && (
                                <h6 style={{ color: "red" }}>{error.email} </h6>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="lastname" className="form-label">
                              Username
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              placeholder="Enter Last name"
                              value={
                                user !== null ? user.username : "Re-Load.."
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="mobile" className="form-label">
                              Role
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="mobile"
                              placeholder="Mobile Number"
                              value={
                                user !== null
                                  ? user.isAdmin === false
                                    ? "User"
                                    : "Admin"
                                  : "Loading.."
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          onClick={(e) => submitForm(user._id, e)}
                          className="btn btn-primary waves-effect waves-light"
                          type="button"
                        >
                          Update
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#changePassordModal"
                          className="text-muted"
                        >
                          <i className="mdi mdi-lock me-1"></i> Change Password
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
