import api from ".";
import axios from "axios";

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("rove-token");
    config.headers.Authorization =  `Bearer ${token}`;
    return config;
});


export const loginApi = data =>{
    return axios.post(api.login,data)
}

export const createuser = data =>{
    return axios.post(api.createuser,data)
}

export const fetchallusers =()=>{
    return axios.get(api.fetchallusers)
}

export const updateuser = (id,data)=>{
    return axios.put(`${api.updateuser}/${id}`,data)
}

export const deleteuser = (id)=>{
    return axios.delete(`${api.deleteuser}/${id}`)
}

export const generateticket = (data, onUploadProgress) => {
  return axios.post(api.generateticket, data, { onUploadProgress });
};

export const getalltickets = (params) => {
    if(params) return axios.get(api.getalltickets,{params})
    return axios.get(api.getalltickets)
}

export const updateticket =(id,data)=>{
    return axios.put(`${api.updateticket}/${id}`,data)
}

export const updateupload =(id,data)=>{
    return axios.put(`${api.updateupload}/${id}`,data)
}

export const deleteticket =(id)=>{
    return axios.delete(`${api.deleteticket}/${id}`)
}
export const viewticket =(id)=>{
    return axios.get(`${api.viewticket}/${id}`)
}

export const sendmessage = (data) => {
    return axios.post(api.sendmessage,data)
}

export const readmessage = (id) => {
    return axios.get(`${api.readmessage}/${id}`)
}

export const deleteMessage = (id) => {
    return axios.delete(`${api.deletemessage}/${id}`)
}

export const changepassword = (data) => {
    return axios.put(`${api.changepassword}`,data)
}

export const createrole = data =>{
    return axios.post(api.createrole,data)
}

export const getallroles = (params) =>{
    if(params) return axios.get(api.getallroles,{params})
    return axios.get(api.getallroles)
}

export const deleterole =(id)=>{
    return axios.delete(`${api.deleterole}/${id}`)
}