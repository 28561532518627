import React, { useState } from "react";
import { generateticket } from "../../api/api";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/toast";

const CreateTicket = () => {
  const initialstate = {
    category: "",
    title: "",
    summary: "",
    priority: "",
    file: [],
    deadline: "",
  };
  const [formsData, setFormData] = useState(initialstate);
  
  const [progress,setProgress] = useState({started:false,pc:0})
    const toast = useToast();
  const navigate = useNavigate()

  const handleChange = (e) => {
    setFormData({ ...formsData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formsData, file: e.target.files });
  };

  const validateForm = () => {
    let errors = {};
    if (formsData.title.length < 4) {
      errors.title = "Title Must Be 4 Charcter.";
       toast({
         title: errors.title,
         status: "warning",
         duration: 2000,
         isClosable: true,
       });
    }
    if (formsData.summary.length === 0) {
      errors.summary = "Please, Enter Information About Problem.";
      toast({
        title: errors.summary,
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    } else if (formsData.summary.length < 10) {
      errors.summary = "Please, Add More Detail About Problem.";
      toast({
        title: errors.summary,
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
    if (formsData.category.length === 0) {
      errors.category = "Please, Select Category.";
      toast({
        title: errors.category,
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
    if (formsData.priority.length === 0) {
      errors.priority = "Please, Select Priority.";
      toast({
        title: errors.priority,
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
   
    return Object.keys(errors).length === 0;
  };

 

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
         let form  = new FormData()
         form.append("title",formsData.title)
         form.append("summary",formsData.summary)
         form.append("category",formsData.category)
         form.append("deadline",formsData.deadline)
         form.append("priority",formsData.priority)
        if (formsData.file.length !== 0) {
          for (let i = 0; i < formsData.file.length; i++) {
            form.append("files", formsData.file[i]);
          }
        }
       setProgress((prev)=>{return {...prev,started:true}})
        let response = await generateticket(form,(progressEvent => setProgress((prev)=>{return {...prev,pc:progressEvent.progress*100}})));
       
        let data = await response.data;
        
        if (data.message) {
           toast({
             title: data.message,
             status: "success",
             duration: 2000,
             isClosable: true,
             position:"top"
           });
          
          setProgress(prev=>{return {...prev,started:false}})
          let timeout = setTimeout(() => {
            
            setFormData(initialstate);
            navigate("/listoftickets");
          }, 2000);
          return () => clearTimeout(timeout);
        }
      } catch (error) {
        if (error.response.data) {
          toast({
            title: error.response.data.error,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      }
    }
  };
  console.log(progress)

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">Create a Ticket</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Ticket</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Create a Ticket
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-10">
                <div className="card">
                  <div className="card-body">
                    <form
                      onSubmit={formSubmitHandler}
                      encType="multipart/form-data"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-md-6">
                          
                          <div className="col-md-12">
                            {progress.started && (
                              <>
                                <progress
                                  max="100"
                                  value={progress.pc}
                                  style={{ width: "100%" }}
                                ></progress>
                                <span>{`${progress.pc}%`}</span>
                              </>
                            )}
                            <div className="mb-3">
                              <label htmlFor="title" className="col-form-label">
                                Title :
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="title"
                                id="title"
                                onChange={handleChange}
                                value={formsData.title}
                              ></input>
                              
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label
                                  htmlFor="category"
                                  className="col-form-label"
                                >
                                  Category :
                                </label>
                                <select
                                  id="category"
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="category"
                                  onChange={handleChange}
                                  value={formsData.category}
                                >
                                  <option defaultValue>Select Category</option>
                                  <option value="Technical">Technical</option>
                                  <option value="User Support">
                                    User Support
                                  </option>
                                  <option value="Maintenance">
                                    Maintenance
                                  </option>
                                  <option value="Communication">
                                    Communication
                                  </option>
                                  <option value="Infrastructure">
                                    Infrastructure
                                  </option>
                                  <option value="Security">Security</option>
                                  <option value="Feedback">Feedback</option>
                                  <option value="Other">Other</option>
                                </select>
                                
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label
                                htmlFor="role-priority"
                                className="col-form-label"
                              >
                                Priority :
                              </label>

                              <select
                                id="role-priority"
                                className="form-select"
                                aria-label="Default select example"
                                name="priority"
                                onChange={handleChange}
                                value={formsData.priority}
                              >
                                <option defaultValue>Select Priority</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                              </select>
                              
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="mb-3">
                              <label
                                htmlFor="summary"
                                className="col-form-label"
                              >
                                Describe Problem :
                              </label>
                              <textarea
                                className="form-control"
                                name="summary"
                                id="summary"
                                rows="4"
                                onChange={handleChange}
                                value={formsData.summary}
                              ></textarea>
                              
                            </div>
                          </div>
                          <div className="row my-2">
                            <div className="col-md-6">
                              <label htmlFor="file" className="col-form-label">
                                Attachments
                              </label>
                              <input
                                multiple
                                type="file"
                                name="file"
                                id="file"
                                className="form-control"
                                onChange={handleFileChange}
                              />
                            </div>

                            <div className="col-md-6">
                              <label
                                htmlFor="deadline"
                                className="col-form-label"
                              >
                                Expected Solveing Date
                              </label>
                              <input
                                type="date"
                                name="deadline"
                                id="deadline"
                                className="form-control"
                                onChange={handleChange}
                                value={formsData.deadline}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button className="btn btn-primary" type="submit">
                          Create Ticket
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;
