import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { deleteuser, fetchallusers, updateuser } from "../../../api/api";
import { useNavigate } from "react-router";
import { useToast } from "@chakra-ui/toast";
import { Link } from "react-router-dom";
const ListofUsers = () => {
  const [users, setUsers] = useState([]);
  const [totalResult ,setTotalResult] = useState("")
  const closeupdate = useRef();
  const navigate = useNavigate()
  const toast = useToast();
  const [updateData, setUpdateData] = useState({ name: "", email: "" });


   const [user, setUser] = useState(null);

   useEffect(() => {
     let userDetail = localStorage.getItem("rove-user");
     if (userDetail) {
       userDetail = JSON.parse(userDetail);
       setUser(userDetail);
     } else {
       console.log("No user Found.");
     }
   }, []);

   useEffect(() => {
     console.log(user);
   }, [user]);
  
  if (user!==null && user.isAdmin===false) {
    navigate("/createticket");
  }

  const fetschUser = async () => {
    try {
      
      let response = await fetchallusers();
      let data = response.data;
      console.log(data);
      if (data.users) {
        setUsers(data.users);
        setTotalResult(data.totalusers)
       
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        toast({
          title: error.response.data.error,
          status: "error",
          duration: 2000,
          isClosable: true,
          position:"top"
        });
        
      }
    }
  };

  useEffect(() => {
    fetschUser();
  }, []);

  const updateUser = (user) => {
   
    setUpdateData(user);
  };

  const handleChange = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (updateData.name.length === 0 || updateData.name.length < 3) {
      errors.name = "Enter Name And Name Must be 3 Characters.";
      toast({
        title: errors.name,
        status: "warning",
        duration: 1000,
        isClosable: true,
        
      });
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(updateData.email)) {
      if (updateData.email.length === 0) {
        errors.email = "Enter Email Address.";
      } else {
        errors.email = "Enter Valid Email Address.";
      }
      toast({
        title: errors.email,
        status: "warning",
        duration: 1000,
        isClosable: true,
      });
    }
    
    return Object.keys(errors).length === 0;
  };

  const updateUserHandler = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        let { _id } = updateData;
        
        let response = await updateuser(_id, updateData);
        let data = response.data;
        console.log(data);
        if (data.message) {
         toast({
           title: data.message,
           status: "success",
           duration: 1000,
           isClosable: true,
           position:"top"
         });
          let timeout = setTimeout(() => {
            fetschUser();
            closeupdate.current.click();
          }, 1000);
          setUpdateData({ name: "", email: "" });
          return () => clearTimeout(timeout);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          
          toast({
            title: error.response.data.error,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        }
      }
    }
  };

  const deleteUser = async (id) => {
    try {
     
      let response = await deleteuser(id);

      let data = await response.data;
      if (data.message) {
       toast({
         title: data.message,
         status: "success",
         duration: 2000,
         isClosable: true,
         position: "top",
       });
        let timeout = setTimeout(() => {
          fetschUser();
        }, 1000);
        return () => clearTimeout(timeout);
      }
      
    } catch (error) {
      if (error.response.data) {
        toast({
          title: error.response.data.error,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        
      }
    }
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">All Registered Users</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="#">Super Admin</a>
                      </li>
                      <li className="breadcrumb-item active">
                        All Registered Users
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-end">
                      <Link
                        to="/create-user"
                        className="btn btn-primary  btn-rounded waves-effect waves-light mb-3"
                        data-bs-whatever="Create New Company"
                      >
                        {/* <i className="fas fa-plus mx-2"></i> */}
                        Create New User
                      </Link>
                    </div>
                    <div className="table-responsive">
                      {users.length === 0 ? (
                        <h3 className="text-center my-5">No Users Found.</h3>
                      ) : (
                        <>
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">Sr No.</th>
                                <th className="align-middle">Name</th>
                                <th className="align-middle">Email</th>

                                <th className="align-middle">Username</th>
                                <th className="align-middle">Role</th>
                                <th className="align-middle" width="200">
                                  Actions
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {users.length > 0 &&
                                users.map((item, index) => (
                                  <>
                                    <tr key={item._id}>
                                      <td className="text-body fw-bold">
                                        {index + 1}
                                      </td>
                                      <td>{item.name}</td>
                                      <td>
                                        <span className="badge badge-soft-primary font-size-14 m-1 p-2">
                                          {item.email}
                                        </span>
                                      </td>

                                      <td>{item.username}</td>
                                      <td>
                                        <span
                                          className={`badge badge-soft-${
                                            item.isAdmin === true
                                              ? "primary"
                                              : "secondary"
                                          } font-size-14 m-1 p-2`}
                                        >
                                          {item.isAdmin === true
                                            ? "Admin"
                                            : "User"}
                                        </span>
                                      </td>

                                      <td className="align-middle">
                                        <div className="d-flex align-items-center">
                                          <a
                                            href="#"
                                            role="button"
                                            onClick={() => updateUser(item)}
                                            className="d-inline-block mx-2"
                                            data-bs-toggle="modal"
                                            data-bs-target="#editPermission"
                                            data-bs-whatever="Edit Role"
                                          >
                                            <i
                                              className="fas fa-edit"
                                              style={{ fontSize: " 20px" }}
                                            ></i>
                                          </a>
                                          <a
                                            href="#"
                                            className="d-inline-block mx-2"
                                            role="button"
                                            onClick={() => deleteUser(item._id)}
                                          >
                                            <i
                                              className="fas fa-trash-alt"
                                              style={{ fontSize: " 20px" }}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>

                    <div className="row mt-3">
                      <div className="col-sm-12 col-md-5">
                        {`Showing ${totalResult} User.`}
                      </div>
                      {/* <div className="col-sm-12 col-md-7">
                        <ul className="pagination justify-content-end">
                          <li
                            className="paginate_button page-item previous disabled"
                            id="datatable-buttons_previous"
                          >
                            <a
                              href="#"
                              aria-controls="datatable-buttons"
                              data-dt-idx="0"
                              tabIndex="0"
                              className="page-link"
                            >
                              Previous
                            </a>
                          </li>
                          <li className="paginate_button page-item active">
                            <a
                              href="#"
                              aria-controls="datatable-buttons"
                              data-dt-idx="1"
                              tabIndex="0"
                              className="page-link"
                            >
                              1
                            </a>
                          </li>
                          <li className="paginate_button page-item ">
                            <a
                              href="#"
                              aria-controls="datatable-buttons"
                              data-dt-idx="2"
                              tabIndex="0"
                              className="page-link"
                            >
                              2
                            </a>
                          </li>
                          <li className="paginate_button page-item ">
                            <a
                              href="#"
                              aria-controls="datatable-buttons"
                              data-dt-idx="3"
                              tabIndex="0"
                              className="page-link"
                            >
                              3
                            </a>
                          </li>
                          <li className="paginate_button page-item ">
                            <a
                              href="#"
                              aria-controls="datatable-buttons"
                              data-dt-idx="4"
                              tabIndex="0"
                              className="page-link"
                            >
                              4
                            </a>
                          </li>
                          <li className="paginate_button page-item ">
                            <a
                              href="#"
                              aria-controls="datatable-buttons"
                              data-dt-idx="5"
                              tabIndex="0"
                              className="page-link"
                            >
                              5
                            </a>
                          </li>
                          <li className="paginate_button page-item ">
                            <a
                              href="#"
                              aria-controls="datatable-buttons"
                              data-dt-idx="6"
                              tabIndex="0"
                              className="page-link"
                            >
                              6
                            </a>
                          </li>
                          <li
                            className="paginate_button page-item next"
                            id="datatable-buttons_next"
                          >
                            <a
                              href="#"
                              aria-controls="datatable-buttons"
                              data-dt-idx="7"
                              tabIndex="0"
                              className="page-link"
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}

        <div
          className="modal fade"
          id="editPermission"
          tabIndex="-1"
          aria-labelledby="UserRoleModal"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="UserRoleModal">
                  Update User Info.
                </h5>
                <button
                  ref={closeupdate}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form id="myForm">
                  <div className="mb-3">
                    <label htmlFor="role-name" className="col-form-label">
                      Name:
                    </label>
                    <input
                      name="name"
                      onChange={handleChange}
                      value={updateData.name}
                      type="text"
                      className="form-control"
                      id="role-name"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="role-email"
                      className="col-form-label d-block"
                    >
                      Email:
                    </label>
                    <input
                      onChange={handleChange}
                      name="email"
                      value={updateData.email}
                      type="text"
                      className="form-control"
                      id="role-email"
                    />
                  </div>

                  {/* <div className="mb-3">
                    <label htmlFor="role-uname" className="col-form-label">
                      Username:
                    </label>
                    <input
                      name="name"
                      type="text"
                      className="form-control"
                      id="role-uname"
                    />
                  </div> */}
                </form>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={updateUserHandler}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListofUsers;
