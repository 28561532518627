import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TicketContext } from "../utils/context";
import { deleteticket, getalltickets } from "../api/api";
import queryString from "query-string";
import { useToast } from "@chakra-ui/toast";
import Pagination from "../component/Pagination";

const ListofTickets = () => {
  // const [updateData, setUpdateData] = useState({ status: "", category: "" });
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState({});
  const [totalresult, setTotalResult] = useState({
    pageTotal: 0,
    totalresult: 0,
  });
 
  const [currentPage, setCurrentPage] = useState(1);

  const [date, setDate] = useState({ start: "", end: "", status: "" });


  const { setFilterTicket } = useContext(TicketContext);
  const toast = useToast();

  const dateHandleChange = (e) => {
    setDate({ ...date, [e.target.name]: e.target.value });
  };

  const TotalPages = Math.ceil(totalresult.totalresult / 10);

  const dateSubmit = async (e) => {
    e.preventDefault();
    try {
      if (
        date.start.length !== 0 &&
        date.end.length !== 0 &&
        date.status.length !== 0
      ) {
        let params = { start: date.start, end: date.end, status: date.status };
        let fil = await getalltickets(params);

        let data = fil.data;
        if (data.tickets) {
          setTickets(data.tickets);
          setFilterTicket(data.tickets);
        }
      } else {
        toast({
          title: "Please Select All Fields For Filter.",
          status: "warning",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTickets = async () => {
    try {
      let params = { limit: 10, skip: (currentPage - 1) * 10 };
      let response = await getalltickets(params);
      let data = response.data;
      
      if (data.tickets) {
        setTickets(data.tickets);
        setTotalResult({
          totalresult: data.totalresult,
          pageTotal: data.pageTotal,
        });
        
      }
    } catch (error) {
      if (error.response) {
        toast({
          title: error.response.data.error,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  
 
 
  

  useEffect(() => {
    // if (first) {
    fetchTickets();
    // setFirst(false);
    // } else {
    // return;
    // }
  }, [currentPage]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Fetch data for the new page, or update the state as needed
  };

  // const handleChange = (e) => {
  //   setUpdateData({ ...updateData, [e.target.name]: e.target.value });
  // };

  // const validate = () => {
  //   let errors = {};
  //   if (updateData.status.length === 0) {
  //     errors.category = "Please, Select Category.";
  //   }
  //   if (updateData.status.length === 0) {
  //     errors.status = "Please, Select Status.";
  //   }
  //   setError(errors);
  //   return Object.keys(errors).length === 0;
  // };

  // const updateTicket = (ticket) => {
  //   let { _id, summary, priority, status,category } = ticket;
  //   ticket = { _id, summary, priority, status,category };
  //   console.log(ticket);
  //   setUpdateData(ticket);
  // };

  // const updateTicketHandler = async (e) => {
  //   e.preventDefault();

  //   if (validate()) {
  //     try {
  //       let id = updateData._id;
  //       let response = await updateticket(id, updateData);

  //       let data = await response.data;
  //       if (data.message) {
  //         setError({ message: data.message });
  //         let timeout = setTimeout(() => {
  //           setError({});
  //         }, 2000);
  //         fetchTickets();
  //         closeupdatemodal.current.click()

  //         return () => clearTimeout(timeout);
  //       }
  //     } catch (error) {
  //       if (error.response.data) {
  //         setError({ error: error.response.data.error });
  //       }
  //     }
  //   }
  // };
  // useEffect(() => {

  // },[filter])

  const deleteTicket = async (id) => {
    const shouldDelete = window.confirm("Are You Sure To Delete This Ticket ?");

    if (shouldDelete) {
      try {
        const response = await deleteticket(id);

        const data = response.data;
        if (data.message) {
          toast({
            title: data.message,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      } catch (error) {
        // Handle network or other errors
        setError({
          error:
            error.response.data.error ||
            "Network error or something went wrong",
        });
        let timeout = setTimeout(() => {
          setError({});
        }, 2000);
        return () => clearTimeout(timeout);
      }
    }

    return;
  };
 
  // const ViewTicket = async (id) => {
  //   context.setTicket(id);
  //   navigate("/listof-study");
  // };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0 font-size-18">List of Tickets</h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      
                      <li className="breadcrumb-item active">
                        List of Tickets
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className=" mb-4">
                      <div className="row d-flex justify-content-between">
                        <div className="col-md-9 mb-3">
                          <form onSubmit={dateSubmit}>
                            <div className="row">
                              <div className="col-md-2 ">
                                <h5
                                  className="col-form-label"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Filter
                                </h5>

                                <select
                                  id="role-select"
                                  className="form-select"
                                  aria-label="Default select example"
                                  name="status"
                                  onChange={dateHandleChange}
                                  value={date.status}
                                >
                                  <option defaultValue value="">
                                    Select Status
                                  </option>
                                  <option value="Open">Open</option>
                                  <option value="In Progress">
                                    In Progress
                                  </option>
                                  <option value="Resolved">Resolved</option>
                                  <option value="Closed">Closed</option>
                                </select>
                              </div>
                              <div className="col-md-2">
                                <label
                                  htmlFor="start"
                                  className="col-form-label"
                                >
                                  Starting Date
                                </label>
                                <input
                                  type="date"
                                  name="start"
                                  id="start"
                                  className="form-control"
                                  onChange={dateHandleChange}
                                  value={date.start}
                                />
                              </div>
                              <div className="col-md-2">
                                <label htmlFor="end" className="col-form-label">
                                  Ending Date
                                </label>
                                <input
                                  type="date"
                                  name="end"
                                  id="end"
                                  className="form-control"
                                  onChange={dateHandleChange}
                                  value={date.end}
                                />
                              </div>
                              <div className="col-md-2 d-flex align-items-end ">
                                <button className="btn btn-primary  ">
                                  Find Tickets
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className=" col-md-2 d-flex flex-column  ">
                          <Link
                            to="/createticket"
                            className="btn btn-primary  btn-rounded waves-effect waves-light mb-3"
                            data-bs-whatever="Create New Company"
                          >
                            {/* <i className="fas fa-plus mx-2"></i> */}
                            Create Ticket
                          </Link>
                         
                          <Link
                            to="/genpdf"
                            className="btn btn-primary  btn-rounded waves-effect waves-light"
                            data-bs-whatever="Create New Company"
                          >
                            {/* <i className="fas fa-plus mx-2"></i> */}
                            Generate XL sheet
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mb-3">
                      {error.error && (
                        <h6 style={{ color: "red" }}>{error.error}</h6>
                      )}
                    </div>
                    {tickets.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap mb-0">
                            <thead className="table-light">
                              <tr>
                                <th className="align-middle">No.</th>
                                <th className="align-middle">Detail</th>
                                <th className="align-middle">Ticket Id</th>
                                <th className="align-middle">Category</th>

                                <th className="align-middle">Priority</th>
                                <th className="align-middle">Created </th>
                                <th className="align-middle">Upadated</th>

                                <th className="align-middle">Status</th>
                                <th className="align-middle">files</th>
                                <th className="align-middle" width="250">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tickets.length > 0 &&
                                tickets.map((ticket, index) => (
                                  <>
                                    <tr key={ticket._id}>
                                      <td className="text-body fw-bold">
                                        {index + 1}
                                      </td>
                                      <td>{ticket.title.slice(0, 16)}...</td>
                                      <td>{ticket.ticket_id}</td>
                                      <td>{ticket.category}</td>

                                      <td>
                                        <span
                                          className={`badge badge-pill ${
                                            ticket.priority === "Low"
                                              ? "badge-soft-primary"
                                              : ticket.priority === "Medium"
                                              ? "badge-soft-info"
                                              : "badge-soft-danger"
                                          } font-size-13`}
                                        >
                                          {ticket.priority}
                                        </span>{" "}
                                      </td>
                                      <td>{ticket.createdAt.slice(0, 19)}</td>
                                      <td>{ticket.updatedAt.slice(0, 19)}</td>

                                      <td>
                                        <span
                                          className={`badge badge-pill ${
                                            ticket.status === "Closed"
                                              ? "badge-soft-success"
                                              : ticket.status === "Resolved"
                                              ? "badge-soft-info"
                                              : "badge-soft-danger"
                                          } font-size-13`}
                                        >
                                          {ticket.status}
                                        </span>
                                      </td>
                                      <td>
                                        {ticket.fileId.length !== 0 &&
                                          ticket.fileId.map((file) => (
                                            <a target="blank" href={file}>
                                              <i
                                                className="mdi mdi-file-alert"
                                                style={{ fontSize: "20px" }}
                                              ></i>
                                            </a>
                                          ))}
                                      </td>
                                      <td className="align-middle">
                                        <div className="d-flex align-items-center">
                                          {/* <div className="d-inline-block mx-2">
                                            <a
                                              role="button"
                                              onClick={() =>
                                                ViewTicket(ticket._id)
                                              }
                                              className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                            >
                                              View
                                            </a>
                                          </div> */}
                                          <div className="d-inline-block mx-2">
                                            <Link
                                              to={`/listof-study?${queryString.stringify(
                                                { _id: ticket._id }
                                              )}`}
                                              className="btn btn-primary btn-sm btn-rounded waves-effect waves-light"
                                            >
                                              View
                                            </Link>
                                          </div>
                                          {/* <a
                                            href="#"
                                            role="button"
                                            onClick={() => updateTicket(ticket)}
                                            data-bs-toggle="modal"
                                            data-bs-target="#AssignUser"
                                            className="d-inline-block mx-2 "
                                          >
                                            <i
                                              className="fas fa-edit"
                                              style={{ fontSize: "20px" }}
                                            ></i>{" "}
                                          </a> */}

                                          <a
                                            href="#"
                                            role="button"
                                            onClick={() =>
                                              deleteTicket(ticket._id)
                                            }
                                            className="d-inline-block mx-2"
                                          >
                                            <i
                                              className="fas fa-trash-alt"
                                              style={{ fontSize: "20px" }}
                                            ></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <h4>No Tickets Found.</h4>
                    )}

                    <div className="row mt-3">
                      <div className="col-sm-12 col-md-5">
                        {`Showing  ${totalresult.pageTotal} results out of Total ${totalresult.totalresult} Tickets.`}
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <ul className="pagination justify-content-end">
                          <li
                            className="paginate_button page-item previous "
                            id="datatable-buttons_previous"
                          >
                            <button
                              onClick={handlePrevPage}
                              aria-controls="datatable-buttons"
                              className="page-link"
                              disabled={currentPage === 1}
                            >
                              Previous
                            </button>
                          </li>
                          {/* <li className="paginate_button page-item active">
                                <a
                                  href="#"
                                  aria-controls="datatable-buttons"
                                  data-dt-idx="1"
                                  tabIndex="0"
                                  className="page-link"
                                >
                                  {currentPage}
                                </a>
                              </li> */}
                          {/* <Pagination
                            totalResults={totalresult.totalresult}
                            resultsPerPage={totalresult.pageTotal}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                          /> */}
                          <li className="paginate_button page-item ">
                            <button
                              onClick={handleNextPage}
                              aria-controls="datatable-buttons"
                              className="page-link"
                              disabled={currentPage === TotalPages}
                              title="view more"
                            >
                              Next
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className="modal fade"
          id="AssignUser"
          tabIndex="-1"
          aria-labelledby="AssignUserStuday"
          aria-hidden="true"
        >
          <div className="modal-dialog ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AssignUserStuday">
                  Update Ticket
                </h5>
                <button
                  type="button"
                  ref={closeupdatemodal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={updateTicketHandler}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="Category" className="col-form-label">
                          Category :
                        </label>

                        <select
                          id="Category"
                          className="form-select"
                          aria-label="Default select example"
                          name="category"
                          onChange={handleChange}
                          value={updateData.category}
                        >
                          <option defaultValue>Select Category</option>
                          <option value="Technical">Technical</option>
                          <option value="User Support">User Support</option>
                          <option value="Maintenance">Maintenance</option>
                          <option value="Communication">Communication</option>
                          <option value="Infrastructure">Infrastructure</option>
                          <option value="Security">Security</option>
                          <option value="Feedback">Feedback</option>
                          <option value="Other">Other</option>
                        </select>
                        {error.category && (
                          <h6 style={{ color: "red" }}>{error.category}</h6>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label
                          htmlFor="role-Responsibility"
                          className="col-form-label"
                        >
                          Status :
                        </label>

                        <select
                          id="role-Responsibility"
                          className="form-select"
                          aria-label="Default select example"
                          name="status"
                          onChange={handleChange}
                          value={updateData.status}
                        >
                          <option defaultValue>Select Priority</option>
                          <option value="Open">Open</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Resolved">Resolved</option>
                          <option value="Closed">Closed</option>
                        </select>
                        {error.status && (
                          <h6 style={{ color: "red" }}>{error.status}</h6>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="text-center mt-4">
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ListofTickets;
