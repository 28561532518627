const ReportTemplate = ({data}) => {
  
  

    return (
      <>
        <div style={{ width: "700px" }} className="text-center">
          <div className=" my-4">
            <h3>Ticket Details</h3>
          </div>
          <table
            className="table"
            style={{
              fontSize: "11px",
              border: "1px solid black",
              marginLeft:"2rem"
            }}
          >
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Title</th>
                <th scope="col">Id</th>
                <th scope="col">Status</th>
                <th scope="col">Category</th>
                <th scope="col">Created At</th>
                <th scope="col">Updated At</th>
                <th scope="col">Finished At</th>
                <th scope="col">Assign To</th>
              </tr>
            </thead>
            {data && data.length > 0 ? (
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{item.title}</td>
                    <td>{item.ticket_id}</td>
                    <td>{item.status}</td>
                    <td>{item.category}</td>

                    <td>{item.createdAt.slice(0, 19)}</td>
                    <td>{item.updatedAt.slice(0, 19)}</td>
                    <td>
                      {item.finished_date !== null
                        ? item.finished_date
                        : "Not Finished Yet."}
                    </td>
                    <td>
                      {item.assign_id.username !== null
                        ? item.assign_id.username
                        : "Not Assigned Yet."}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <h4>No Data Found.</h4>
            )}
          </table>
        </div>
      </>
    );
};

export default ReportTemplate;

