
let host = "192.168.1.12" ||"localhost";
let port = "9000"
const api = {
  login: `http://${host}:${port}/admin/adminlogin`,
  createuser: `http://${host}:${port}/admin/createnewuser`,
  fetchallusers: `http://${host}:${port}/admin/allusers`,
  updateuser: `http://${host}:${port}/admin/updateuser`,
  deleteuser: `http://${host}:${port}/admin/deleteUser`,
  generateticket: `http://${host}:${port}/ticket/createnewticket`,
  getalltickets: `http://${host}:${port}/ticket/showtickets`,
  updateticket: `http://${host}:${port}/ticket/updateticket`,
  deleteticket: `http://${host}:${port}/ticket/deleteticket`,
  viewticket: `http://${host}:${port}/ticket/viewticket`,
  sendmessage: `http://${host}:${port}/ticket/sendmessage`,
  readmessage: `http://${host}:${port}/ticket/readmessages`,
  deletemessage: `http://${host}:${port}/ticket/deletemessage`,
  changepassword: `http://${host}:${port}/admin/changepassword`,
  updateupload: `http://${host}:${port}/ticket/updatedocuments`,
  createrole: `http://${host}:${port}/userRole/create_role`,
  getallroles: `http://${host}:${port}/userRole/get_role`,
  deleterole: `http://${host}:${port}/userRole/s_delete`
};

export default api