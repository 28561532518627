import { createContext, useEffect, useState } from "react";

export const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  const [role, setRole] = useState(null);
  const [filterRole, setFilterRole] = useState(null);
  const [loginUser, setLoginUser] = useState(null);

  useEffect(() => {
    let user = localStorage.getItem("rove-user");
    if (user) {
      user = JSON.parse(user);
      setLoginUser(user);
    } else {
      console.log("No User Found In localstorage.");
    }
  }, []);

  useEffect(() => {
    console.log(loginUser);
  }, [loginUser]);

  return (
    <RoleContext.Provider
      value={{ role, setRole, loginUser, filterRole, setFilterRole }}
    >
      {children}
    </RoleContext.Provider>
  );
};
